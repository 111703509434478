import { Navigate, Outlet } from 'react-router-dom';
import LoginPage from 'workspace/pages/Login/LoginPage';
import ManageUsersPage from 'workspace/pages/ManageUsersPage';
import ManageBooksPage from 'workspace/pages/ManageBooksPage';
import ManageWorkspacePage from 'workspace/pages/ManageWorkspacePage';
import AuthProvider from 'workspace/providers/auth/AuthContext';
import ProtectedRoutes from './ProtectedRoutes';
import RoleBasedRoutes from './RoleBasedRoutes';
import { ROLES } from 'workspace/constants';

const WorkSpaceRoutes = [
  {
    path: '/ws',
    element: (
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        element: <ProtectedRoutes />,
        children: [
          {
            path: '',
            element: <Navigate to="manageusers" />,
          },
          {
            path: 'manageusers',
            element: (
              <RoleBasedRoutes allowedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}>
                <ManageUsersPage />
              </RoleBasedRoutes>
            ),
          },
          {
            path: 'managebooks',
            element: (
              <RoleBasedRoutes allowedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.AUTHOR]}>
                <ManageBooksPage />
              </RoleBasedRoutes>
            ),
          },
          {
            path: 'manageworkspace',
            element: (
              <RoleBasedRoutes allowedRoles={[ROLES.SUPER_ADMIN]}>
                <ManageWorkspacePage />
              </RoleBasedRoutes>
            ),
          },
        ],
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
    ],
  },
];

export default WorkSpaceRoutes;
